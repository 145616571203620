import { useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { NetworkStatus, useQuery } from '@apollo/client';
import { differenceInCalendarDays, format } from 'date-fns';
import { ErrorRendererPage, parseGraphQLErrorExtensions } from '@hs-baumappe/redkit';
import { ErrorCode } from '@hs-baumappe/graphql-errors';

import { GetPublicOvertimePdfPathQuery } from './graphql/GetPublicOvertimePdfPath.query';
import ExternalHeader from '../../../../../components/ExternalHeader';
import PublicOvertimeExpired from './components/PublicOvertimeExpired';
import PublicOvertimeDetailPdfView from './components/PublicOvertimeDetailPdfView';
import PublicOvertimeDetailThankYou from './components/PublicOvertimeDetailThankYou';
import Footer from '../../../../../components/Footer';
import { PublicOvertimeFragment } from '../graphql/__generated__/PublicOvertime.fragment';
import { getTextColorByOvertimeStatus } from './PublicOvertimeDetailContainerBody.utils';

interface PublicOvertimeDetailContainerBodyProps {
  overtime: PublicOvertimeFragment;
  onSignOvertimeClick: () => void;
}

export default function PublicOvertimeDetailContainerBody({
  overtime,
  onSignOvertimeClick,
}: PublicOvertimeDetailContainerBodyProps): JSX.Element {
  const { t } = useTranslation();
  const {
    id: overtimeId,
    status,
    date,
    signedByCustomer,
    contactPersonEmail,
    accessDueDate,
    projectLogo,
  } = overtime;

  const { data, loading, error, networkStatus } = useQuery(GetPublicOvertimePdfPathQuery, {
    variables: { overtimeId },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return <LayoutLoading />;
  }

  const graphQLError = error?.graphQLErrors.at(0);
  const accessExpiredError =
    !!graphQLError &&
    parseGraphQLErrorExtensions(graphQLError)?.code === ErrorCode.ACCESS_EXPIRED_ERROR;
  if (error && !accessExpiredError) {
    return <ErrorRendererPage apolloError={error} />;
  }

  const accessDaysRemaining = differenceInCalendarDays(new Date(accessDueDate), new Date());
  const expired = accessDaysRemaining <= 0;

  return (
    <Stack>
      <ExternalHeader logoSrc={projectLogo ? `data:image/png;base64,${projectLogo}` : undefined} />

      <Box
        stylex={{
          paddingEnds: 'large',
        }}
      >
        <div>
          <Text component="h1" variant="title-large" className="u-margin-ends-0">
            {t('publicOvertimeDetail.title', { date: format(new Date(date), 'dd.MM.yyyy') })}
          </Text>

          {!expired && !signedByCustomer && (
            <Text color={getTextColorByOvertimeStatus(status)}>
              {t('publicOvertimeDetail.status', { status: t(status) })}
            </Text>
          )}
        </div>

        <div className="u-margin-top-large">
          {expired && <PublicOvertimeExpired contactPersonEmail={contactPersonEmail} />}

          {data && !expired && signedByCustomer && (
            <PublicOvertimeDetailThankYou
              daysRemaining={accessDaysRemaining}
              overtime={overtime}
              overtimePdfPath={data.overtimePdfPath}
            />
          )}

          {data && !expired && !signedByCustomer && (
            <PublicOvertimeDetailPdfView
              daysRemaining={accessDaysRemaining}
              overtime={overtime}
              overtimePdfPath={data.overtimePdfPath}
              onSignOvertimeClick={onSignOvertimeClick}
            />
          )}
        </div>
      </Box>

      {!expired && !signedByCustomer && <Footer />}
    </Stack>
  );
}
